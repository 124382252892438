@import "bs4/functions";
@import "bs4/variables";
@import "bs4/mixins";

@import "global-modules/custom-mixin";
@import "global-modules/placeholder";


.event-list-box{
  @include rem(padding, 20px);
  @include rem(margin-bottom, 24px);
  border: 3px solid $gray-600;

  &__list{
    @include rem(margin-bottom, 12px);
   
    >li{
      display: flex;
      justify-content: center;
      @include rem(padding, 5px 0 5px 0);

      &:not(:last-child){
        @include rem(margin-bottom, 5px);
      }

      .date{
        flex: 1 0 40px;
        max-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $gray-400;
        background-color: $gray-200;
        

        >div{
          @include rem(font-size, 13px);
          line-height: 1.2;
          flex: 1 0 100%;
          text-align: center;

          .number{
            @include rem(font-size, 14px);
            font-weight: 500;
            color: #333;
          }
          .month{
            @include rem(font-size, 11px);
            font-weight: 400;
            color: #333;
            text-transform: uppercase;
          }
        }
      }

      .desc{
        flex: 1 0 unquote("calc(100% - 40px)");
        max-width: unquote("calc(100% - 40px)");
        @include rem(padding-left, 15px);
        border-bottom: 1px solid $gray-400;

        p{
          @include rem(font-size, 14px); 
          font-weight: 400;
          margin-bottom: 3px;
          line-height: 1.2;
        }

        .small{
          line-height: 1.2;
          margin-bottom: 3px;
        }

        .link{
          @include rem(font-size, 13px);
          @include rem(margin-bottom, 4px);
        }
      }
    }
  }

  >p:last-child{
    margin-bottom: 0;
  }
}



