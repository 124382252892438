



$baseFontSize: 16px;

@mixin rem($property, $px-values) {

  $baseline-rem: $baseFontSize / 1rem;

  //#{$property}: $px-values;

  @if type-of($px-values) == "number" {
    #{$property}: $px-values / $baseline-rem;
  }
  @else{
    $rem-values: unquote("");
    @each $value in $px-values {
      @if $value == 0 or type-of($value) == "string" or type-of($value) == "color" {
        $rem-values: append($rem-values, $value);
      }
      @else {
        $rem-values: append($rem-values, $value / $baseline-rem);
      }
    }
    #{$property}: $rem-values;
  }
}

//   @include rem(padding, 16px 13px);
//   @include rem(font-size, 16px);


@mixin svg-size($vbw, $new-width) {
  $vbh: 24px; // default viewbox height
  width: $new-width;
  height: (($vbh / $vbw) * $new-width);
}

//@include svg-size(15px,20px);




@function force-rgb($color) {
  //@return unquote("rgb(#{red($color)}, #{green($color)}, #{blue($color)})");
  @return unquote("#{red($color)}, #{green($color)}, #{blue($color)}");
}

@function force-rgba($color, $a) {
  @return unquote("rgba(#{red($color)}, #{green($color)}, #{blue($color)},#{$a})");
}

@function get-hue($color) {
   $tempvalu: unquote("#{hue($color)}");
   @return str-slice($tempvalu,0,7);
}

@function get-saturation($color) {
  @return unquote("#{saturation($color)}");
}

@function get-lightness($color) {
  @return unquote("#{lightness($color)}");
}

@function force-hsl($color,$alpha) {
  //$color: if(length($args) == 1, nth($args, 1), hsl($args...));
  @return unquote("hsla(#{get-hue($color)}, #{saturation($color)}, #{lightness($color)}, #{$alpha})");
}

@function hslvalue($color) {
  @return unquote("#{get-hue($color)}, #{saturation($color)}, #{lightness($color)}");
}

@function perc($width, $container-width: $max-width) {
  @return percentage($width / $container-width);
}



// @function force-hsl-2($color) {
//   @return unquote("hsl(#{get-hue($color)}, #{saturation($color)}, #{lightness($color)})");
// }



